import dayjs from 'dayjs';
import router from '../router';
import { resetPassword, logUserOut, getAllPermissions } from '@/services/authServices';

export default {
  namespaced: true,
  state: () => ({
    currentUser: {},
    expiresAt: null,
    token: null,
    lastActivity: Date.now(),
  }),
  getters: {
    currentUser: ({ currentUser }) => currentUser,
    isLoggedIn: ({ expiresAt, token }) =>
      !!token && dayjs().isBefore(dayjs(expiresAt)),
    token: ({ token }) => token,
  },
  mutations: {
    SET_CURRENT_USER(state, payload) {
      state.currentUser = payload;
    },
    SET_EXPIRATION(state, payload) {
      state.expiresAt = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_AUTH_PERMISSIONS_LIST: (state, payload) => {
      state.permissionsList = payload;
    },
    UPDATE_LAST_ACTIVITY(state) {
      state.lastActivity = Date.now();
    },
    CLEAR_USER(state) {
      state.currentUser = {};
      state.expiresAt = null;
      state.token = null;
    },
  },
  actions: {
    async logout({ dispatch, commit }, payload) {
      try {
        if (payload) {
          await logUserOut(payload);
        }

        commit('CLEAR_USER');
        dispatch('updateLastActivity');
        router.replace({ name: 'Login' });

        dispatch(
          'notifications/displayNotification',
          { message: 'You have been logged out', type: 'info' },
          { root: true }
        );
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message || statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async resetPassword({ dispatch }, details) {
      try {
        const data = await resetPassword(details);
        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: `Password reset`, type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getAllPermissions({ commit, dispatch }) {
      try {
        const { data } = await getAllPermissions();
        commit('SET_AUTH_PERMISSIONS_LIST', data.data);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    updateLastActivity({ commit }) {
      commit('UPDATE_LAST_ACTIVITY');
    },
  },
};
